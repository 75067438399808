<template>
  <div>
    <div v-if="!this.loaded"></div>
    <div v-else>
      <div class="header">
        <div><img :src="logoBrand" /></div>
        <div>
          <div>
            <h1>{{ config.title }}</h1>
          </div>
          <small>émis le {{ $moment().format("ddd D MMM à H:mm") }} - fuseau horaire : {{ config.timezone }} (UTC{{
            utcOffset > 0 ? '+' + utcOffset : utcOffset }})</small>
        </div>
        <div><img :src="setup.logo" /></div>
      </div>

      <div class="content">
        <table style="width:100%">
          <thead>
            <tr class="center" style="border-bottom: 1px solid white;">
              <th></th>
              <th colspan="2" style="border-left:1px solid white;border-right: 1px solid white;">
                Nuit prochaine
              </th>
              <th>À 7:00<br />demain</th>
              <th colspan="4" style="border-left:1px solid white">
                Prochaines 24h
              </th>
            </tr>
            <tr>
              <th>Zone</th>
              <th style="border-left:1px solid white">T min<br /><small>(°C)</small></th>
              <th style="border-right: 1px solid white">Hr max<br /><small>(%)</small></th>
              <th style="text-align: center;">Temp.<br /><small>(°C)</small></th>
              <th style="border-left:1px solid white">Vent moy. max.<br /><small>(km/h)</small></th>
              <th>Vent max.<br /><small>(km/h)</small></th>
              <th>Nébul.<br />moy. <small>(%)</small></th>
              <th>Précip.</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="castpoint in config.castpoints" :key="castpoint.id">
              <td>{{ castpoint.name }} <small>alt: {{ pointSummary(castpoint.id).altitude }}m</small></td>
              <td style="border-left:1px solid rgba(134, 131, 131, 0.3)">{{ pointSummary(castpoint.id).tminNight }}</td>

              <td>{{ pointSummary(castpoint.id).hrmaxNight }}</td>

              <td
                style="text-align:center;border-left:1px solid rgba(134, 131, 131, 0.3);border-right:1px solid rgba(134, 131, 131, 0.3)">
                {{ pointSummary(castpoint.id).t7AM }}
              </td>
              <td align="center">{{ pointSummary(castpoint.id).windAvgMax24h }}</td>
              <td>{{ pointSummary(castpoint.id).windMax24h }}</td>
              <td>{{ pointSummary(castpoint.id).nebulAvg24h }}</td>
              <td>
                <template v-if="pointSummary(castpoint.id).accRain24h > 0 || pointSummary(castpoint.id).accSnow24h > 0">
                  <template v-if="pointSummary(castpoint.id).accRain24h > 0">Pluie, {{
                    pointSummary(castpoint.id).accRain24h
                  }}mm</template>
                  <br />
                  <template v-if="pointSummary(castpoint.id).accSnow24h > 0">Neige, {{
                    pointSummary(castpoint.id).accSnow24h
                  }}cm
                  </template>
                </template>
                <template v-else>-</template>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="bulletin">
          <h2>Analyse du météorologue</h2>
          <small>publiée le
            {{
              $moment(bulletin.time_publish * 1e3).format("ddd D MMM à H:mm")
            }}</small>
          <vue-markdown :source="bulletin.text" class="bulletin__text"></vue-markdown>
        </div>
      </div>

      <div v-if="ready" id="loaded"></div> <!-- pour que pupeeter detecte que la page est prete -->
    </div>
  </div>
</template>

<script>
import api from "../api";
import VueMarkdown from "vue-markdown";
// import forecastDevData from '@/assets/forecast-cd56.json'; // dev

export default {
  name: "BulletinZone",

  components: {
    VueMarkdown,
  },

  data: () => ({
    loaded: false,
    ready: false,
    setup: null,
    config: null,
    utcOffset: null,
    castpoints: null,
    forecast: null,
    // forecast: forecastDevData, // dev
    bulletin: null
  }),

  computed: {
    lang: function () {
      return this.$route.query.lang ? this.$route.query.lang : "fr";
    },
    logoBrand: function () {
      if (!this.setup || (!this.setup.brand && !this.setup.brand_light))
        return "/img/logo-mg.png";
      const brand = this.setup.brand_light || this.setup.brand;
      return brand.match(/(\.svg)|(\.png)$/) ? brand : "/img/logo-mg.png";
    },

    pointSummary() {
      return idCastpoint => {
        let accRain24h = 0, accSnow24h = 0;
        let windAvgMax24h = null, windMax24h = null, tminNight = null, hrmaxNight = null, t7AM = null;
        let nebulAcc24h = [];
        let i = 0;
        for (let timeData of this.forecast[idCastpoint].data) {
          accRain24h += timeData.rain;
          accSnow24h += timeData.snow;
          nebulAcc24h.push(timeData.cloud);
          if (windAvgMax24h === null || timeData.w_avg > windAvgMax24h) {
            windAvgMax24h = timeData.w_avg;
          }
          if (windMax24h === null || timeData.w_max > windMax24h) {
            windMax24h = timeData.w_max;
          }
          let localHour = this.$moment(timeData.time * 1000).hours();
          if (localHour >= 22 || localHour <= 6) { // night
            if (tminNight === null || timeData.tair < tminNight) {
              tminNight = timeData.tair;
            }
            if (hrmaxNight === null || timeData.rh > hrmaxNight) {
              hrmaxNight = timeData.rh;
            }
          }
          if (localHour == 7) {
            t7AM = timeData.tair;
          }
          if (++i > 24) break;
        }
        return {
          altitude: this.forecast[idCastpoint].point.elev,
          accRain24h: Math.round(accRain24h), accSnow24h: Math.round(accSnow24h), windAvgMax24h, windMax24h,
          nebulAvg24h: Math.round(nebulAcc24h.reduce((acc, val) => acc + val, 0) / nebulAcc24h.length),
          tminNight, hrmaxNight,
          t7AM
        }
      }
    }
  },

  methods: {
    fetchAllForecasts: async (castpoints, time, batchSize) => {
      let allResults = {};

      for (let i = 0; i < castpoints.length; i += batchSize) {
        const batch = castpoints.slice(i, i + batchSize);
        const batchResults = await Promise.all(
          batch.map(async (castpoint) => ({
            [castpoint.id]: await api.castpoint.getForecast(
              castpoint.id,
              1,
              time
            ),
          }))
        );
        batchResults.forEach((result) => {
          const key = Object.keys(result)[0];
          allResults[key] = result[key];
        });
      }
      return allResults;
    }
  },

  async mounted() {
    const { idSetup, idZone, lang } = this.$route.params;
    const { apiurl, token, time } = this.$route.query;

    api.setApiUrl(apiurl);
    api.setToken(token);

    this.$i18n.locale = lang ? lang : "fr";
    this.$moment.locale(this.$i18n.locale);
    this.time = time || Math.round(Date.now() / 1000);

    try {
      this.setup = await api.setup.get(idSetup);
      this.config = this.setup.bulletin_zones.find((item) => item.id == idZone);
      this.$moment.tz.setDefault(this.config.timezone);
      this.utcOffset = this.$moment().utcOffset() / 60;
      this.bulletin = await api.humancast.getOfCastzoneChannel(
        this.config.castzone,
        this.config.channel || this.setup.bulletin_channel,
        this.time
      );
      if (this.forecast === null) { // si pas dev
        this.forecast = await this.fetchAllForecasts(
          this.config.castpoints,
          this.time,
          8 // chunk size
        );
      }
      this.loaded = true;
      this.$nextTick(() => {
        this.ready = true;
      });
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

html {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;

  img {
    height: 100%;
  }
}

.header>div:nth-child(1),
.header>div:nth-child(3) {
  flex: 0 1 auto;
  align-self: flex-start;
  height: 100%;
}

.header div:nth-child(2) {
  flex: 1 0 auto;
  padding-left: 10px;
}

h1,
h2 {
  margin: 0;
}

table {
  border-collapse: collapse;
  margin: 25px 0;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

thead tr {
  background-color: #7298cd;
  color: #ffffff;
  text-align: left;
}

th {
  padding: 4px 10px;
}

td {
  padding: 8px 10px;
}

tbody tr {
  border-bottom: 1px solid #dddddd;
}

thead tr.center {
  text-align: center;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #7298cd;
}

tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

@page {
  margin: 10px;
}

@media print {
  * {
    max-width: 100%;
  }

  body {
    margin: 0;
  }

  header,
  footer,
  nav,
  aside {
    display: none;
  }
}</style>
